















































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import DstvPackage from "@/components/DstvPackage.vue";
import DstvChannel, { DstvChannelItem } from "@/components/DstvChannel.vue";
import ChannelData from "@/assets/data/dstv-channel-list.json";
import { SelectItem } from "@/types/veutify";
import { addAnalyticsEvent } from "@/plugins/firebase";

interface RawChannel {
    NAME: string;
    "CHANNEL NUM": number;
    "CHANNEL LOGO": string;
    "CHANNEL CATEGORY": string;
    CONFAM: number | string;
    COMPACT: number | string;
    "COMPACT +": number | string;
    PREMIUM: number | string;
}

type RawPackageName = "CONFAM" | "COMPACT" | "COMPACT +" | "PREMIUM";

@Component({
    components: { DstvChannel, DstvPackage, BackButton },
})
export default class ChannelsList extends Vue {
    dstvCategory = "all";

    dstvCategoryList: Array<SelectItem> = [
        {
            value: "all",
            text: "All",
        },
        {
            value: "Movies",
            text: "Movies",
        },
        {
            value: "Sport",
            text: "Sport",
        },
        {
            value: "Music",
            text: "Music",
        },
        {
            value: "Lifestyle and culture",
            text: "Lifestyle and culture",
        },
        {
            value: "Novelas",
            text: "Novelas",
        },
        {
            value: "Documentary",
            text: "Documentary",
        },
        {
            value: "News and Business",
            text: "News and Business",
        },
        {
            value: "General Entertainment",
            text: "General Entertainment",
        },
        {
            value: "Kids & Teens",
            text: "Kids & Teens",
        },
        {
            value: "Miscellaneous",
            text: "Miscellaneous",
        },
    ];

    categoryChanged() {
        addAnalyticsEvent("dstv_channel_category_changed", {
            category: this.dstvCategory,
        });
    }

    get channelList(): Array<DstvChannelItem> {
        const channels = ChannelData.filter(
            (channel: RawChannel) => channel[this.packageName]
        );

        if (this.dstvCategory == "all") {
            return this.mapToDstvChannelItem(channels);
        }

        return this.mapToDstvChannelItem(
            channels.filter(
                (channel: RawChannel) =>
                    channel["CHANNEL CATEGORY"] == this.dstvCategory
            )
        );
    }

    mapToDstvChannelItem(channels: Array<RawChannel>): Array<DstvChannelItem> {
        return channels
            .map((channel: RawChannel) => {
                return {
                    name: channel.NAME,
                    category: channel["CHANNEL CATEGORY"],
                    channel: channel["CHANNEL NUM"],
                    logo:
                        channel["CHANNEL LOGO"] == ""
                            ? null
                            : channel["CHANNEL LOGO"],
                };
            })
            .sort((first: DstvChannelItem, second: DstvChannelItem): number => {
                const firstIndex = this.dstvCategoryList.findIndex(
                    (category) => category.value === first.category
                );
                const secondIndex = this.dstvCategoryList.findIndex(
                    (category) => category.value === second.category
                );

                if (firstIndex != secondIndex) {
                    return firstIndex - secondIndex;
                }

                return (
                    [first.name.toLowerCase(), second.name.toLowerCase()]
                        .sort()
                        .indexOf(first.name.toLowerCase()) - 1
                );
            });
    }

    get packageTitle(): string {
        const path = location.pathname.substr(
            location.pathname.lastIndexOf("/") + 1
        );
        return path.replace("-package", "").replace("-", " ");
    }

    get packageName(): RawPackageName {
        const path = location.pathname.substr(
            location.pathname.lastIndexOf("/") + 1
        );
        return (
            new Map<string, RawPackageName>([
                ["comfam-package", "CONFAM"],
                ["compact-package", "COMPACT"],
                ["compact-plus-package", "COMPACT +"],
                ["premium-package", "PREMIUM"],
            ]).get(path) ?? "CONFAM"
        );
    }
}
